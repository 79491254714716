import { defineNuxtPlugin } from "#app";
import * as Sentry from "@sentry/browser";

export default defineNuxtPlugin((nuxtApp) => {
  const release = `<mobile.md>@${new Date().toISOString()}`;

  if (process.env.NODE_ENV !== "development") {
    Sentry.init({
      dsn: "https://2eef7282751985dbe6a8849b350ec410@o577952.ingest.us.sentry.io/4507787436818432",
      release,
      environment: process.env.NODE_ENV,
      sampleRate: 1,
      tracesSampleRate: 1,
      // ignore error loading dynamically imported module
      ignoreErrors: [
        "'text/html' is not a valid JavaScript MIME type.",
        "Failed to fetch",
      ]
    });
  }
});
