<script setup lang="ts">
useSeoMeta({
  title: 'Pagina nu a fost gasita',
  description: 'Ne pare rau, dar pagina accesata nu a fost gasita.',
})

const props = defineProps({
  error: {
    type: Object,
    required: true
  }
})

// if (import.meta.client && props.error.statusCode == 500) {
//   window.location.reload()
// }
</script>

<template>
  <NuxtLayout>
    <UContainer>
      <UPage>
        <UPageError :error="error" />
      </UPage>
    </UContainer>
  </NuxtLayout>
</template>
