import { default as _91_46_46_46path_93HAWnv82ve5Meta } from "/vercel/path0/pages/catalog/[...path].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as cookies_46serverZIT3ldbQ8sMeta } from "/vercel/path0/pages/legal/cookies.server.vue?macro=true";

let _createIslandPage
async function createIslandPage (name) {
  _createIslandPage ||= await import("/vercel/path0/node_modules/nuxt/dist/components/runtime/server-component").then(r => r.createIslandPage)
  return _createIslandPage(name)
};
import { default as index_46servermntKpbh069Meta } from "/vercel/path0/pages/legal/index.server.vue?macro=true";
import { default as privacy_46serverGIjbXfvdltMeta } from "/vercel/path0/pages/legal/privacy.server.vue?macro=true";
import { default as terms_46serverSJzwU7CGL9Meta } from "/vercel/path0/pages/legal/terms.server.vue?macro=true";
import { default as _91uuid_938vh6Lbpja0Meta } from "/vercel/path0/pages/parts/[uuid].vue?macro=true";
import { default as indexV941xrFpRhMeta } from "/vercel/path0/pages/parts/index.vue?macro=true";
import { default as requestsAmdcuViYUWMeta } from "/vercel/path0/pages/parts/search/requests.vue?macro=true";
import { default as sentry_45example_45pageQDS8XtWeNGMeta } from "/vercel/path0/pages/sentry-example-page.vue?macro=true";
import { default as bonus_45malus_46servergcaaOwxP3iMeta } from "/vercel/path0/pages/tools/bonus-malus.server.vue?macro=true";
import { default as carte_45verde_46serverXAKxRJmktLMeta } from "/vercel/path0/pages/tools/carte-verde.server.vue?macro=true";
import { default as emissionsctpkJe7vsSMeta } from "/vercel/path0/pages/tools/emissions.vue?macro=true";
import { default as fuellJMeRHU4zLMeta } from "/vercel/path0/pages/tools/fuel.vue?macro=true";
import { default as import_45futureMukRo6eo3lMeta } from "/vercel/path0/pages/tools/import-future.vue?macro=true";
import { default as importl72I9qosyMMeta } from "/vercel/path0/pages/tools/import.vue?macro=true";
import { default as index_46serveriaXGcloyZzMeta } from "/vercel/path0/pages/tools/index.server.vue?macro=true";
import { default as insurance_45calculator_46serverAiGFqDBHByMeta } from "/vercel/path0/pages/tools/insurance-calculator.server.vue?macro=true";
import { default as insurance_45check_46serverjfmuQjEiwUMeta } from "/vercel/path0/pages/tools/insurance-check.server.vue?macro=true";
import { default as insurance_45claims_45history_46serverEZ6O51tKdtMeta } from "/vercel/path0/pages/tools/insurance-claims-history.server.vue?macro=true";
import { default as lost_45foundg4E0o9I2W7Meta } from "/vercel/path0/pages/tools/lost-found.vue?macro=true";
import { default as _91slug_93VK6mSS9wj1Meta } from "/vercel/path0/pages/vehicles/archive/[vin]/[slug].vue?macro=true";
import { default as indexwfZFhEevbOMeta } from "/vercel/path0/pages/vehicles/archive/[vin]/index.vue?macro=true";
import { default as indexhP5HBjlbxwMeta } from "/vercel/path0/pages/vehicles/archive/index.vue?macro=true";
import { default as indexdkgr5gN20kMeta } from "/vercel/path0/pages/vehicles/index.vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "catalog-path___en",
    path: "/en/catalog/:path(.*)*",
    component: () => import("/vercel/path0/pages/catalog/[...path].vue").then(m => m.default || m)
  },
  {
    name: "catalog-path___ro___default",
    path: "/catalog/:path(.*)*",
    component: () => import("/vercel/path0/pages/catalog/[...path].vue").then(m => m.default || m)
  },
  {
    name: "catalog-path___ro",
    path: "/ro/catalog/:path(.*)*",
    component: () => import("/vercel/path0/pages/catalog/[...path].vue").then(m => m.default || m)
  },
  {
    name: "catalog-path___ru",
    path: "/ru/katalog/:path(.*)*",
    component: () => import("/vercel/path0/pages/catalog/[...path].vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ro___default",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ro",
    path: "/ro",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-cookies___en",
    path: "/en/legal/cookies",
    component: () => createIslandPage("legal-cookies___en")
  },
  {
    name: "legal-cookies___ro___default",
    path: "/legale/politica-cookies",
    component: () => createIslandPage("legal-cookies___ro___default")
  },
  {
    name: "legal-cookies___ro",
    path: "/ro/legale/politica-cookies",
    component: () => createIslandPage("legal-cookies___ro")
  },
  {
    name: "legal-cookies___ru",
    path: "/ru/zakonodatelstvo/politika-cookies",
    component: () => createIslandPage("legal-cookies___ru")
  },
  {
    name: "legal___en",
    path: "/en/legal",
    component: () => createIslandPage("legal___en")
  },
  {
    name: "legal___ro___default",
    path: "/legale",
    component: () => createIslandPage("legal___ro___default")
  },
  {
    name: "legal___ro",
    path: "/ro/legale",
    component: () => createIslandPage("legal___ro")
  },
  {
    name: "legal___ru",
    path: "/ru/zakonodatelstvo",
    component: () => createIslandPage("legal___ru")
  },
  {
    name: "legal-privacy___en",
    path: "/en/legal/privacy",
    component: () => createIslandPage("legal-privacy___en")
  },
  {
    name: "legal-privacy___ro___default",
    path: "/legale/confidentialitate",
    component: () => createIslandPage("legal-privacy___ro___default")
  },
  {
    name: "legal-privacy___ro",
    path: "/ro/legale/confidentialitate",
    component: () => createIslandPage("legal-privacy___ro")
  },
  {
    name: "legal-privacy___ru",
    path: "/ru/zakonodatelstvo/confidentialnost",
    component: () => createIslandPage("legal-privacy___ru")
  },
  {
    name: "legal-terms___en",
    path: "/en/legal/terms",
    component: () => createIslandPage("legal-terms___en")
  },
  {
    name: "legal-terms___ro___default",
    path: "/legale/termeni",
    component: () => createIslandPage("legal-terms___ro___default")
  },
  {
    name: "legal-terms___ro",
    path: "/ro/legale/termeni",
    component: () => createIslandPage("legal-terms___ro")
  },
  {
    name: "legal-terms___ru",
    path: "/ru/zakonodatelstvo/usloviya",
    component: () => createIslandPage("legal-terms___ru")
  },
  {
    name: "parts-uuid___en",
    path: "/en/parts/:uuid()",
    component: () => import("/vercel/path0/pages/parts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "parts-uuid___ro___default",
    path: "/piese/:uuid()",
    component: () => import("/vercel/path0/pages/parts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "parts-uuid___ro",
    path: "/ro/piese/:uuid()",
    component: () => import("/vercel/path0/pages/parts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "parts-uuid___ru",
    path: "/ru/zapchasti/:uuid()",
    component: () => import("/vercel/path0/pages/parts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: "parts___en",
    path: "/en/parts",
    component: () => import("/vercel/path0/pages/parts/index.vue").then(m => m.default || m)
  },
  {
    name: "parts___ro___default",
    path: "/piese",
    component: () => import("/vercel/path0/pages/parts/index.vue").then(m => m.default || m)
  },
  {
    name: "parts___ro",
    path: "/ro/piese",
    component: () => import("/vercel/path0/pages/parts/index.vue").then(m => m.default || m)
  },
  {
    name: "parts___ru",
    path: "/ru/zapchasti",
    component: () => import("/vercel/path0/pages/parts/index.vue").then(m => m.default || m)
  },
  {
    name: "parts-search-requests___en",
    path: "/en/parts/search/requests",
    component: () => import("/vercel/path0/pages/parts/search/requests.vue").then(m => m.default || m)
  },
  {
    name: "parts-search-requests___ro___default",
    path: "/piese/cautare/solicitari",
    component: () => import("/vercel/path0/pages/parts/search/requests.vue").then(m => m.default || m)
  },
  {
    name: "parts-search-requests___ro",
    path: "/ro/piese/cautare/solicitari",
    component: () => import("/vercel/path0/pages/parts/search/requests.vue").then(m => m.default || m)
  },
  {
    name: "parts-search-requests___ru",
    path: "/ru/zapchasti/poisk/zaprosy",
    component: () => import("/vercel/path0/pages/parts/search/requests.vue").then(m => m.default || m)
  },
  {
    name: "sentry-example-page___en",
    path: "/en/sentry-example-page",
    component: () => import("/vercel/path0/pages/sentry-example-page.vue").then(m => m.default || m)
  },
  {
    name: "sentry-example-page___ro___default",
    path: "/sentry-example-page",
    component: () => import("/vercel/path0/pages/sentry-example-page.vue").then(m => m.default || m)
  },
  {
    name: "sentry-example-page___ro",
    path: "/ro/sentry-example-page",
    component: () => import("/vercel/path0/pages/sentry-example-page.vue").then(m => m.default || m)
  },
  {
    name: "sentry-example-page___ru",
    path: "/ru/sentry-example-page",
    component: () => import("/vercel/path0/pages/sentry-example-page.vue").then(m => m.default || m)
  },
  {
    name: "tools-bonus-malus___en",
    path: "/en/tools/bonus-malus",
    component: () => createIslandPage("tools-bonus-malus___en")
  },
  {
    name: "tools-bonus-malus___ro___default",
    path: "/instrumente/bonus-malus",
    component: () => createIslandPage("tools-bonus-malus___ro___default")
  },
  {
    name: "tools-bonus-malus___ro",
    path: "/ro/instrumente/bonus-malus",
    component: () => createIslandPage("tools-bonus-malus___ro")
  },
  {
    name: "tools-bonus-malus___ru",
    path: "/ru/instrumenty/bonus-malus",
    component: () => createIslandPage("tools-bonus-malus___ru")
  },
  {
    name: "tools-carte-verde___en",
    path: "/en/tools/carte-verde",
    component: () => createIslandPage("tools-carte-verde___en")
  },
  {
    name: "tools-carte-verde___ro___default",
    path: "/instrumente/carte-verde",
    component: () => createIslandPage("tools-carte-verde___ro___default")
  },
  {
    name: "tools-carte-verde___ro",
    path: "/ro/instrumente/carte-verde",
    component: () => createIslandPage("tools-carte-verde___ro")
  },
  {
    name: "tools-carte-verde___ru",
    path: "/ru/instrumenty/zelenaja-karta",
    component: () => createIslandPage("tools-carte-verde___ru")
  },
  {
    name: "tools-emissions___en",
    path: "/en/tools/emissions",
    component: () => import("/vercel/path0/pages/tools/emissions.vue").then(m => m.default || m)
  },
  {
    name: "tools-emissions___ro___default",
    path: "/instrumente/emisii",
    component: () => import("/vercel/path0/pages/tools/emissions.vue").then(m => m.default || m)
  },
  {
    name: "tools-emissions___ro",
    path: "/ro/instrumente/emisii",
    component: () => import("/vercel/path0/pages/tools/emissions.vue").then(m => m.default || m)
  },
  {
    name: "tools-emissions___ru",
    path: "/ru/instrumenty/vybrosov",
    component: () => import("/vercel/path0/pages/tools/emissions.vue").then(m => m.default || m)
  },
  {
    name: "tools-fuel___en",
    path: "/en/tools/fuel",
    component: () => import("/vercel/path0/pages/tools/fuel.vue").then(m => m.default || m)
  },
  {
    name: "tools-fuel___ro___default",
    path: "/instrumente/combustibil",
    component: () => import("/vercel/path0/pages/tools/fuel.vue").then(m => m.default || m)
  },
  {
    name: "tools-fuel___ro",
    path: "/ro/instrumente/combustibil",
    component: () => import("/vercel/path0/pages/tools/fuel.vue").then(m => m.default || m)
  },
  {
    name: "tools-fuel___ru",
    path: "/ru/instrumenty/toplivo",
    component: () => import("/vercel/path0/pages/tools/fuel.vue").then(m => m.default || m)
  },
  {
    name: "tools-import-future___en",
    path: "/en/tools/import-future",
    component: () => import("/vercel/path0/pages/tools/import-future.vue").then(m => m.default || m)
  },
  {
    name: "tools-import-future___ro___default",
    path: "/instrumente/devamare-viitor",
    component: () => import("/vercel/path0/pages/tools/import-future.vue").then(m => m.default || m)
  },
  {
    name: "tools-import-future___ro",
    path: "/ro/instrumente/devamare-viitor",
    component: () => import("/vercel/path0/pages/tools/import-future.vue").then(m => m.default || m)
  },
  {
    name: "tools-import-future___ru",
    path: "/ru/instrumenty/import-budushchego",
    component: () => import("/vercel/path0/pages/tools/import-future.vue").then(m => m.default || m)
  },
  {
    name: "tools-import___en",
    path: "/en/tools/import",
    component: () => import("/vercel/path0/pages/tools/import.vue").then(m => m.default || m)
  },
  {
    name: "tools-import___ro___default",
    path: "/instrumente/devamare",
    component: () => import("/vercel/path0/pages/tools/import.vue").then(m => m.default || m)
  },
  {
    name: "tools-import___ro",
    path: "/ro/instrumente/devamare",
    component: () => import("/vercel/path0/pages/tools/import.vue").then(m => m.default || m)
  },
  {
    name: "tools-import___ru",
    path: "/ru/instrumenty/import",
    component: () => import("/vercel/path0/pages/tools/import.vue").then(m => m.default || m)
  },
  {
    name: "tools___en",
    path: "/en/tools",
    component: () => createIslandPage("tools___en")
  },
  {
    name: "tools___ro___default",
    path: "/intrumente",
    component: () => createIslandPage("tools___ro___default")
  },
  {
    name: "tools___ro",
    path: "/ro/intrumente",
    component: () => createIslandPage("tools___ro")
  },
  {
    name: "tools___ru",
    path: "/ru/instrumenty",
    component: () => createIslandPage("tools___ru")
  },
  {
    name: "tools-insurance-calculator___en",
    path: "/en/tools/insurance-calculator",
    component: () => createIslandPage("tools-insurance-calculator___en")
  },
  {
    name: "tools-insurance-calculator___ro___default",
    path: "/instrumente/calculator-rca",
    component: () => createIslandPage("tools-insurance-calculator___ro___default")
  },
  {
    name: "tools-insurance-calculator___ro",
    path: "/ro/instrumente/calculator-rca",
    component: () => createIslandPage("tools-insurance-calculator___ro")
  },
  {
    name: "tools-insurance-calculator___ru",
    path: "/ru/instrumenty/kalkulyator-osago",
    component: () => createIslandPage("tools-insurance-calculator___ru")
  },
  {
    name: "tools-insurance-check___en",
    path: "/en/tools/insurance-check",
    component: () => createIslandPage("tools-insurance-check___en")
  },
  {
    name: "tools-insurance-check___ro___default",
    path: "/instrumente/verificare-rca",
    component: () => createIslandPage("tools-insurance-check___ro___default")
  },
  {
    name: "tools-insurance-check___ro",
    path: "/ro/instrumente/verificare-rca",
    component: () => createIslandPage("tools-insurance-check___ro")
  },
  {
    name: "tools-insurance-check___ru",
    path: "/ru/instrumenty/proverka-osago",
    component: () => createIslandPage("tools-insurance-check___ru")
  },
  {
    name: "tools-insurance-claims-history___en",
    path: "/en/tools/insurance-claims-history",
    component: () => createIslandPage("tools-insurance-claims-history___en")
  },
  {
    name: "tools-insurance-claims-history___ro___default",
    path: "/instrumente/istoric-daune",
    component: () => createIslandPage("tools-insurance-claims-history___ro___default")
  },
  {
    name: "tools-insurance-claims-history___ro",
    path: "/ro/instrumente/istoric-daune",
    component: () => createIslandPage("tools-insurance-claims-history___ro")
  },
  {
    name: "tools-insurance-claims-history___ru",
    path: "/ru/instrumenty/istoriya-ubytkov",
    component: () => createIslandPage("tools-insurance-claims-history___ru")
  },
  {
    name: "tools-lost-found___en",
    path: "/en/tools/lost-found",
    component: () => import("/vercel/path0/pages/tools/lost-found.vue").then(m => m.default || m)
  },
  {
    name: "tools-lost-found___ro___default",
    path: "/instrumente/pierdut-gasit",
    component: () => import("/vercel/path0/pages/tools/lost-found.vue").then(m => m.default || m)
  },
  {
    name: "tools-lost-found___ro",
    path: "/ro/instrumente/pierdut-gasit",
    component: () => import("/vercel/path0/pages/tools/lost-found.vue").then(m => m.default || m)
  },
  {
    name: "tools-lost-found___ru",
    path: "/ru/instrumenty/propal-najden",
    component: () => import("/vercel/path0/pages/tools/lost-found.vue").then(m => m.default || m)
  },
  {
    name: "vehicles-archive-vin-slug___en",
    path: "/en/vehicles/archive/:vin()/:slug()",
    component: () => import("/vercel/path0/pages/vehicles/archive/[vin]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "vehicles-archive-vin-slug___ro___default",
    path: "/automobile/arhiva/:vin()/:slug()",
    component: () => import("/vercel/path0/pages/vehicles/archive/[vin]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "vehicles-archive-vin-slug___ro",
    path: "/ro/automobile/arhiva/:vin()/:slug()",
    component: () => import("/vercel/path0/pages/vehicles/archive/[vin]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "vehicles-archive-vin-slug___ru",
    path: "/ru/avtomobili/arhiv/:vin()/:slug()",
    component: () => import("/vercel/path0/pages/vehicles/archive/[vin]/[slug].vue").then(m => m.default || m)
  },
  {
    name: "vehicles-archive-vin___en",
    path: "/en/vehicles/archive/:vin()",
    component: () => import("/vercel/path0/pages/vehicles/archive/[vin]/index.vue").then(m => m.default || m)
  },
  {
    name: "vehicles-archive-vin___ro___default",
    path: "/automobile/arhiva/:vin()",
    component: () => import("/vercel/path0/pages/vehicles/archive/[vin]/index.vue").then(m => m.default || m)
  },
  {
    name: "vehicles-archive-vin___ro",
    path: "/ro/automobile/arhiva/:vin()",
    component: () => import("/vercel/path0/pages/vehicles/archive/[vin]/index.vue").then(m => m.default || m)
  },
  {
    name: "vehicles-archive-vin___ru",
    path: "/ru/avtomobili/arhiv/:vin()",
    component: () => import("/vercel/path0/pages/vehicles/archive/[vin]/index.vue").then(m => m.default || m)
  },
  {
    name: "vehicles-archive___en",
    path: "/en/vehicles/archive",
    component: () => import("/vercel/path0/pages/vehicles/archive/index.vue").then(m => m.default || m)
  },
  {
    name: "vehicles-archive___ro___default",
    path: "/automobile/arhiva",
    component: () => import("/vercel/path0/pages/vehicles/archive/index.vue").then(m => m.default || m)
  },
  {
    name: "vehicles-archive___ro",
    path: "/ro/automobile/arhiva",
    component: () => import("/vercel/path0/pages/vehicles/archive/index.vue").then(m => m.default || m)
  },
  {
    name: "vehicles-archive___ru",
    path: "/ru/avtomobili/arhiv",
    component: () => import("/vercel/path0/pages/vehicles/archive/index.vue").then(m => m.default || m)
  },
  {
    name: "vehicles___en",
    path: "/en/vehicles",
    component: () => import("/vercel/path0/pages/vehicles/index.vue").then(m => m.default || m)
  },
  {
    name: "vehicles___ro___default",
    path: "/automobile",
    component: () => import("/vercel/path0/pages/vehicles/index.vue").then(m => m.default || m)
  },
  {
    name: "vehicles___ro",
    path: "/ro/automobile",
    component: () => import("/vercel/path0/pages/vehicles/index.vue").then(m => m.default || m)
  },
  {
    name: "vehicles___ru",
    path: "/ru/avtomobili",
    component: () => import("/vercel/path0/pages/vehicles/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/en-US-sitemap.xml",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/ro-RO-sitemap.xml",
    component: component_45stub5X4Ei38PMg
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/ru-RU-sitemap.xml",
    component: component_45stub5X4Ei38PMg
  }
]