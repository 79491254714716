export default defineAppConfig({
  ui: {
    primary: "selective-yellow",
    gray: "zinc",
    colorMode: {
      preference: "dark",
    },
    button: {
      base: "transition",
      default: {
        size: "md",
      },
    },
    formGroup: {
      error: "m-0",
    },
    input: {
      default: {
        size: "md",
      },
    },
    card: {
      rounded: "rounded-xl",
      background: "bg-gray-100/50 dark:bg-gray-800/25",
    },
    footer: {
      top: {
        wrapper: "border-t border-gray-200 dark:border-gray-800",
        container: "py-8 lg:py-16",
      },
      bottom: {
        wrapper: "border-t border-gray-200 dark:border-gray-800",
      },
    },
    page: {
      hero: {
        wrapper: "lg:py-24",
      },
      card: {
        wrapper:
          "cursor-pointer hover:shadow-lg transition hover:ring-2 hover:ring-primary-500 dark:hover:ring-primary-400 bg-gray-100/50 dark:bg-gray-800/50 hover:bg-gray-100/50 dark:hover:bg-gray-800/50",
      },
    },
    content: {
      prose: {
        code: {
          icon: {
            rs: "vscode-icons:file-type-rust",
          },
        },
      },
    },
  },
});
